import React from 'react'

function Banner() {
  return (
    <section id="banner">
        <div id="home" class="container">
            <div class="cta">
                <h2>Pac Soft LLC</h2>
                <p class="cta_text">We develop responsive web applications, API services, and mobile applications that promote your initiatives.</p>
            </div>
            <ul class="actions special">
                <li><a href="#contact" class="button primary">Contact Us</a></li>
                <li><a href="#services" class="button">Services</a></li>
            </ul>
        </div>
    </section>
  )
}

export default Banner