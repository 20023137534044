import React from 'react'

function About() {
  return (
        
            <section id="about" class="box special">
                <header id="mission" class="major">
                    <h2>Thank you for choosing us
                    <br />
                    &nbsp; for your software development needs.</h2>
                    <p>Our mission is to develop solutions to support initiatives and help them grow to meet their potential by transforming ideas into products or enhancements to existing products and processes.</p>
                </header>
                
            </section>

    )
}

export default About