import logo from './logo.svg';
import './App.css';
import Nav from './components/navigation';
import Banner from './components/banner';
import About from './components/about';
import Services from './components/services';
import Contact from './components/contact';
import Footer from './components/footer';

function App() {
  return (
    <div id="page-wrapper">
			<Nav />
			<Banner />

      <section id="main" class="container">
        <About />	
        <Services />
      </section>

      <Contact />
      <Footer />
      

		</div>
  );
}

export default App;
