import React from 'react'

function Footer() {
  return (
    <footer id="footer">
        <ul class="copyright">
            <li>&copy; <a href="https://pacsoft.io">Pac Soft LLC</a> </li>
        </ul>
    </footer>

  )
}

export default Footer