import React from 'react'

function Services() {
  return (
       
    <section id="services" class="box special features">
        <header class="major">
            <h2>Services
                <div>
                    <span class="section_text">We develop</span>
                </div>
            </h2>
        </header>
        <div class="features-row">
            
            <section>
                <span class="icon solid major fa-sharp fa-solid fa-desktop accent2"></span>
                <h3>Web applications</h3>
                
            </section>

            <section>
                <span class="icon solid major fa-solid fa-mobile accent3"></span>
                <h3>Mobile Applications</h3>
            </section>
        
        </div>
        <div class="features-row">
            
            <section>
                <span class="icon solid major fa-gear accent4"></span>
                <h3>API Services</h3>
                <p></p>
            </section>
            
            <section>
                <span class="icon solid major fa-gears accent5"></span>
                <h3>API Intergrations</h3>
                <p></p>
            </section>
        </div>
    </section>
     
  )
}

export default Services