import React from 'react'

function Contact() {
  return (
    <section id="cta">
        <h2 id="contact">Contact Us</h2>
        <div class="cta">
            <p class="cta_text">Please tell us about your project by filling out the contact form below, and if additional information is needed, we'll contact you.</p>
        </div>
        

        <form method="post" id="Contactform">
            <div class="row gtr-50 gtr-uniform">
                <div class="col-6 col-12-mobilep">
                    <input type="text" name="name" id="name" value="" placeholder="Name" required />
                </div>
                <div class="col-6 col-12-mobilep">
                    <input type="email" name="email" id="email" value="" placeholder="Email" required />
                </div>
                <div class="col-12">
                    <input type="text" name="subject" id="subject" value="" placeholder="Subject" required />
                </div>
                <div class="col-12">
                    <textarea name="message" id="message" placeholder="Enter your message" rows="6" required></textarea>
                </div>
                <div class="col-12">
                    <ul class="actions special">
                        <li><input type="submit" value="Send Message" /></li>
                    </ul>
                </div>
            </div>
        </form>

    </section>
  )
}

export default Contact