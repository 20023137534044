import React from 'react'

function HeaderNavigation() {
  return (
        <header id="header" class="alt">
            <div class="container">
                <h1>
                    <a href="https://pacspft.io">
                        <img src="assets\css\images\logo-no-background.png" width="180px" alt="Pac Soft LLC"/> 
                    </a>
                </h1>
                <nav id="nav">
                    <ul>
                        <li><a href="https://pacsoft.io">Home</a></li>
                        <li><a href="#mission">Mission</a></li>
                        <li><a href="#services">Services</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </nav>
                
            </div>
        </header>
        )
}

export default HeaderNavigation